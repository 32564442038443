import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image';

import AnimationWrapper from './animationWrapper';
import Button from './button';
import CustomAniLink from './customAniLink';


const PostItem = ( { post }  ) => {
    return (
      <article
        className={`blog-list-item tile tile-active ${post.frontmatter.featuredpost ? 'is-featured' : ''}`}
        key={post.id}
      >
        <AnimationWrapper>
          <CustomAniLink Link={post.frontmatter.slug}>
            {post.frontmatter?.videoID ? (
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${post.frontmatter.videoID}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <>
                {post.frontmatter?.image_main && (
                  <div className="post-thumbnail">
                    <GatsbyImage
                      image={post.frontmatter.image_main.childImageSharp.gatsbyImageData}
                      alt={post.frontmatter.title}
                    />
                  </div>
                )}
              </>
            )}
            <div className="post-contents">
              <div className="post-meta">
                <div className="post-title">{post.frontmatter.title}</div>
              </div>
              <div className="section-description post-excerpt">{post.excerpt}</div>
              <Button btnStyle="dark-alt read-more" btnLink={post.frontmatter.slug} btnText="Czytaj dalej →" />
            </div>
          </CustomAniLink>
        </AnimationWrapper>
      </article>
    )
  }

export default PostItem
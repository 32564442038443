import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import PostItem from './PostItem';

import './BlogRoll.css';

const BlogRollTemplate = (props) => {
  const getYouTubeVideoId = (url) => {
    const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);
    return match && match[1];
  };

  const { edges: posts } = props.data.allMdx;
  posts.forEach(function (post) {
    post.node.frontmatter.slug = post.node.frontmatter.slug ? post.node.frontmatter.slug : post.node.fields.slug;
    if (post.node.frontmatter.featured_video) {
      post.node.frontmatter.videoID = getYouTubeVideoId(post.node.frontmatter.featured_video);
    }
  });

  return (
    <div className="tile-section blog-tile-section">
      {posts &&
        posts.map((_, index) => {
          if (index === 1) {
            return (
              <div className="posts-container" key="posts-container">
                <PostItem post={posts[1].node} />
                <PostItem post={posts[2].node} />
                <PostItem post={posts[3].node} />
              </div>
            );
          }
          if (index === 2 || index === 3 ) {
            return null; // Skip rendering second and third post since it's already rendered above
          }
          return <PostItem post={posts[index].node} key={posts[index].node.id} />;
        })}
    </div>
  );
};

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { type: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 125)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  slug
                  description
                  date(formatString: "DD/MM/YYYY")
                  featured_video
                  image_main {
                    childImageSharp {
                      gatsbyImageData(transformOptions: { fit: COVER }, webpOptions: { quality: 95 })
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
